/* *************************************** */
/* ****  START Language Drop List   ****** */
/* *************************************** */

.css-yk16xz-control {
  width: 100px;
  font-size: var(--font-size-md);
  margin-left: auto;
  border-radius: var(b--border-radius-16) !important;
}
.css-1pahdxg-control,
.css-1pahdxg-control:hover {
  max-width: 120px;
  border-radius: var(b--border-radius-16) !important;
  border-color: var(--color-primary-dark) !important;
  box-shadow: 0 0 0 1px var(--color-primary-dark);
}
.css-1okebmr-indicatorSeparator {
  margin-bottom: unset !important;
  margin-top: unset !important;
  width: unset !important;
}
.css-9gakcf-option {
  background-color: var(--color-primary-dark);
}

.lang-btn .dropdown-toggle {
  background-color: var(--color-secondary-dark);
  border: unset;
  color: var(--color-B2B2B2);
  text-transform: uppercase;
  font-size: var(--font-size-sm);
  border-radius: 14px;
  width: 100%;
}
.css-yk16xz-control {
  width: 100px;
  font-size: var(--font-size-md);
  margin-left: auto;
  border-radius: var(b--border-radius-16) !important;
}
.css-1pahdxg-control,
.css-1pahdxg-control:hover {
  max-width: 120px;
  border-radius: var(b--border-radius-16) !important;
  border-color: var(--color-primary-dark) !important;
  box-shadow: 0 0 0 1px var(--color-primary-dark);
}
.css-1okebmr-indicatorSeparator {
  margin-bottom: unset !important;
  margin-top: unset !important;
  width: unset !important;
}
.css-9gakcf-option {
  background-color: var(--color-primary-dark);
}
/* *************************************** */
/* ****  END Language Drop List   ****** */
/* *************************************** */
