/* *************************************** */
/* *****  START FOOTER STYLE************** */
/* *************************************** */
.pages .link {
  border-top: 1px solid #fff;
}
.tabs li:after {
  content: "";
  position: absolute;
  min-height: 12px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  display: block;
  top: 8px;
  right: -18px;
}
.tabs li:last-of-type:after {
  content: unset;
}
.footer {
  color: #ccc;
  padding: 50pt 0 40pt;
}
.space a {
  padding-right: 2%;
}

.footer p {
  color: var(--color-primary-dark);
}
.row-centered {
  text-align: center;
}
.green {
  color: var(--color-primary-dark);
}
/* *************************************** */
/* *****  END FOOTER STYLE************** */
/* *************************************** */
