.shareContainer {
  text-align: end;
}
.shareContainer .basic {
  background: transparent;
  border: unset;
  color: aliceblue;
}

.shareContainer > div {
  cursor: pointer;
}
