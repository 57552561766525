/* *************************************** */
/* ********  START COMPETITION *********** */
/* *************************************** */
.competitionContainer .play-btn {
  z-index: 2;
}
.competitionContainer .card-img-top {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
     object-position: top;
  min-height: unset;
  max-height: 250px;
}

@media (max-width: 991.98px) {
  .competitionContainer .card-body {
    padding: unset;
  }
  .competitionContainer .btn {
    padding: 12px 0;
    font-size: 10px !important;
  }
}

/* *************************************** */
/* ********   END COMPETITION  *********** */
/* *************************************** */
