/* *************************************** */
/* ********  START WEB HOME  ************* */
/* *************************************** */
.homeWebContainer .card,
.homeWebContainer .circle-icon {
  border: 1px solid var(--color-tertiary-dark);
  background: var(--color-secondary-dark);
  border-radius: var(--border-radius-48);
  cursor: pointer;
}

.homeWebContainer .circle-icon {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 35%;
  top: -50px;
  border-radius: 70px;
  z-index: 5;
}

.homeWebContainer .circle-icon svg {
  margin: 30px;
}

.homeWebContainer .card:hover,
.homeWebContainer .card:hover .circle-icon {
  transition: 0.4s;
  border: 1px solid var(--color-primary-light);
}

.homeWebContainer .circle-icon > img {
  color: var(--color-primary-dark);
  width: inherit;
  padding: 30px;
}
/* *************************************** */
/* ********  END WEB HOME  *************** */
/* *************************************** */
