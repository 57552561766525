/* *************************************** */
/* ********  START EDIT PROFILE  ********* */
/* *************************************** */

.editContainer {
  border-radius: 25px;
}
/* *************************************** */
/* ********  END EDIT PROFILE  ********* */
/* *************************************** */
