.react-responsive-modal-root .user-img {
  width: 100px;
  height: 100px;
}

@media (max-width: 767.98px) {
  .react-responsive-modal-root .user-img {
    width: 80px;
    height: 80px;
  }
}
