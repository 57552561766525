/* *************************************** */
/* ********  START USER DATA  ************ */
/* *************************************** */
.userDataContainer .user-img {
  width: 75px;
  height: 75px;
}

.userDataContainer .edit-btn {
  text-align: end;
}
.userDataContainer span,
.userDataContainer svg {
  cursor: pointer;
}

.userDataContainer .edit-img {
  position: absolute;
  color: var(--color-primary-dark);
  bottom: 0px;
  right: 55px;
  background: white;
  border-radius: 5px;
}
/* *************************************** */
/* ********  START PROFILE WEB  ********** */
/* *************************************** */

.userDataContainer .nav-tabs {
  border-bottom: unset;
}

.userDataContainer span,
.userDataContainer svg {
  cursor: pointer;
}
.userDataContainer .nav-tabs .nav-link {
  border: unset;
}
.userDataContainer .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent;
}

.userDataContainer .nav-link.active {
  color: var(--color-primary-light);
}

/* *************************************** */
/* ********  END PROFILE WEB  ********** */
/* *************************************** */

/* / Small devices (landscape phones, SM  / */
@media (max-width: 767.98px) {
  .userDataContainer .user-img {
    width: 50px;
    height: 50px;
  }
  .userDataContainer .edit-img {
    right: 15px;
  }
}

/* *************************************** */
/* ********  END USER DATA  ********** */
/* *************************************** */
