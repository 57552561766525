/* *************************************** */
/* *******  START FONTS SIZE    ********** */
/* *************************************** */

@font-face {
  font-family: "font-Light";
  src: url(../fonts/Helvetica.ttf);
}

@font-face {
  font-family: "font-Light-ar";
  src: url(../fonts/GE_SS_Two_Light.otf);
}

:root {
  --font-light-ar: "font-Light-ar";
  --font-light: "font-Light";
  --font-medium: "font-Medium";
  --font-bold: "font-Bold";
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-xm: 18px;
  --font-size-xxm: 22px;
  --font-size-lg: 24px;
  --font-size-xl: 26px;
  --font-size-xxl: 40px;
}

.fs-xxs {
  font-size: var(--font-size-xxs) !important;
}
.fs-xs {
  font-size: var(--font-size-xs) !important;
}
.fs-sm {
  font-size: var(--font-size-sm) !important;
}
.fs-md {
  font-size: var(--font-size-md) !important;
}
.fs-xm {
  font-size: var(--font-size-xm) !important;
}
.fs-xxm {
  font-size: var(--font-size-xxm) !important;
}
.fs-lg {
  font-size: var(--font-size-lg) !important;
}
.fs-xl {
  font-size: var(--font-size-xl) !important;
}
.fs-xxl {
  font-size: var(--font-size-xxl) !important;
}
@media (max-width: 767.98px) {
  .fs-sm {
    font-size: var(--font-size-xs) !important;
  }
  .fs-md {
    font-size: var(--font-size-xs) !important;
  }
  .fs-xm {
    font-size: var(--font-size-sm) !important;
  }
  .fs-xxm {
    font-size: var(--font-size-md) !important;
  }
  .fs-lg {
    font-size: var(--font-size-xm) !important;
  }
  .fs-xl {
    font-size: var(--font-size-xxm) !important;
  }
}
/* *************************************** */
/* *******  END FONTS SIZE      ********** */
/* *************************************** */
