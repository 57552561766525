/* *************************************** */
/* *****  START BANNER COMPETITION ******* */
/* *************************************** */

.gift-img {
  margin: 15px;
}

.gift-tooltip .tooltip-inner {
  max-width: 300px;
  background-color: var(--color-secondary-dark);
  color: var(--color-tertiary-light);
  border: 1px solid var(--color-secondary-xlight);
  border-radius: var(--border-radius-16);
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: var(--color-secondary-dark);
}

.eclipse {
  background-color: var(--color-secondary-xlight);
  border-radius: 60px;
  width: 75px;
  height: 75px;
  cursor: pointer;
}
.calendar-date-cont {
  color: #ffff;
  background: #ffffff52;
  padding-bottom: 2px;
  z-index: 0;
  /* position: absolute; */
  border-radius: 0px 0px 3px 3px;
}
.calendat-box-1,
.calendat-box-2 {
  width: 50px;
  height: 40px;
  background-color: #090909;
  border: 0.5px solid #090909;
}
.calendat-box-1 {
  background: transparent linear-gradient(180deg, #212121 0%, #0d0d0d 100%) 0%
    0% no-repeat padding-box;
  border-radius: unset;
}
.calendat-box-2 {
  background: transparent linear-gradient(180deg, #212121 0%, #0d0d0d 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 0px 3px 3px;
}

.calendar-date {
  position: absolute;
  font-size: 39px;
  z-index: 10;
  margin: 15px;
}
@media (max-width: 991.98px) {
  .eclipse {
    width: 60px;
    height: 60px;
  }
  .gift-img {
    width: 40px;
    margin: 10px;
  }
  .calendar-date-container {
    position: absolute;
  }
  .calendar-date-container > div:lang(en) {
    position: absolute;
    right: 10px;
  }
  .calendar-date-container > div:lang(ar) {
    position: absolute;
    left: 10px;
  }
  .calendat-box-1,
  .calendat-box-2 {
    width: 35px;
    height: 30px;
  }
  .calendar-date {
    font-size: 25px;
    margin: 12px;
  }
}

.calendar-date-container:lang(ar) {
  direction: ltr;
}
/* *************************************** */
/* *****  END BANNER COMPETITION ******* */
/* *************************************** */
