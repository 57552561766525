/* // Extra small devices XS */
@media (max-width: 575.98px) {
  .play-btn {
    padding: 5px 15px;
  }
  .page_loader_container img {
    position: absolute;
    left: 26%;
    top: 35%;
  }
  .player-wrapper,
  .react-player__preview,
  .subscription-image {
    min-height: 230px;
    border-radius: 10px;
  }
  .navbar-logo {
    width: 120px;
  }
  .css-yk16xz-control {
    font-size: var(--font-size-xs);
  }

  .articlesCardContainer img {
    position: unset;
    border-radius: unset;
  }
  video {
    border-radius: 10px;
  }
}

/* / Small devices (landscape phones, SM  / */
@media (min-width: 576px) and (max-width: 767.98px) {
  .page_loader_container img {
    position: absolute;
    left: 37%;
    top: 35%;
  }
  .player-wrapper,
  .react-player__preview,
  .subscription-image {
    min-height: 350px;
  }
  .navbar-logo {
    width: 120px;
  }
  .articlesCardContainer img {
    position: unset;
    border-radius: unset;
  }
  video {
    border-radius: 10px;
  }
}

/* // Medium devices MD */
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-logo {
    width: 120px;
  }
  .page_loader_container img {
    position: absolute;
    left: 38%;
    top: 35%;
  }
  .intro-section .player-wrapper,
  .intro-section .react-player__preview,
  .player-wrapper video,
  .intro-section .subscription-image {
    min-height: 300px;
  }
  .articlesCardContainer img {
    position: unset;
    border-radius: unset;
  }
  .player-wrapper,
  .react-player__preview,
  .subscription-image {
    min-height: 300px;
  }
  video {
    border-radius: 10px;
  }
}

/* // Large devices LG*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-logo {
    width: 120px;
  }
  .page_loader_container img {
    position: absolute;
    left: 40%;
    top: 35%;
  }
  .intro-section .player-wrapper,
  .intro-section .react-player__preview,
  .player-wrapper video,
  .intro-section .subscription-image {
    min-height: 260px;
    border-radius: 20px;
  }
  .react-player {
    height: 500px !important;
  }
}

/* // Extra large devices XL*/
@media (min-width: 1200px) {
  /* .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  } */

  .intro-section {
    padding-top: 100px;
  }
  .intro-section .navbar_1 {
    position: unset;
  }

  .intro-section .player-wrapper,
  .intro-section .react-player__preview,
  .intro-section video,
  .intro-section .subscription-image {
    min-height: 300px;
    border-radius: 20px;
  }
  .intro-section .react-player__preview {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .intro-section {
    background-image: url("/src/assets/img/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .intro-video {
    height: 100vh;
  }

  .navbar-logo {
    width: 150px;
  }
  /* .react-player {
    height: 300px !important;
  } */
}
