/* *************************************** */
/* ********  START WORKOUT MOBILE  ******* */
/* *************************************** */
.workOutMobi .player-wrapper,
.workOutMobi .react-player__preview,
.workOutMobi {
  border-radius: unset;
}
.workOutMobi .card-header {
  background: unset;
  border-bottom: unset;
}
.workOutMobi video {
  display: block;
  border-radius: 5px;
}
.workOutMobi .card-body {
  padding: unset;
}

.workOutMobi .play-btn {
  top: unset;
  right: unset;
}
/* *************************************** */
/* ********  END WORKOUT MOBILE  ******* */
/* *************************************** */
/* *************************************** */
