/* *************************************** */
/* ******* START RANKING STYLE *********** */
/* *************************************** */
.user-img {
  width: 50px;
  height: 50px;
}

/* *************************************** */
/* ******* START RANKING STYLE *********** */
/* *************************************** */
