.navbar_2 .navbar-nav:lang(en) {
  margin-left: auto;
}
.navbar_2 .active {
  color: var(--color-primary-light) !important;
}
.navbar_2 .navbar-nav:lang(ar) {
  margin-right: auto;
}
.navbar_2 .navbar {
  padding: 0.5em 0;
}
.navbar_2 .nav-link {
  color: var(--color-tertiary) !important;
}

.navbar_2 .navbar-logo {
  width: 140px;
}

.navbar_2 .dropdown-item:hover,
.navbar_2 .nav-link:hover {
  color: var(--color-primary-light) !important;
}

.sc-bxivhb {
  text-align: center;
}
