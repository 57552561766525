/* *************************************** */
/* *******      START COLORS    ********** */
/* *************************************** */

:root {
  --color-primary-light: #004f90;
  --color-primary-dark: #004073;
  --color-secondary-xlight: #3d3d3d;
  --color-secondary-light: #1e1e1e;
  --color-secondary: #292929;
  --color-secondary-dark: #212121;
  --color-tertiary-xlight: #b1b1b1;
  --color-tertiary-light: #e2e2e2;
  --color-tertiary: #a6a6a6;
  --color-tertiary-dark: #757575;
}

.color-primary-light {
  color: var(--color-primary-light) !important;
}
.color-primary-dark {
  color: var(--color-primary-dark) !important;
}
.color-secondary-xlight {
  color: var(--color-secondary-xlight) !important;
}
.color-secondary-light {
  color: var(--color-secondary-light) !important;
}
.color-secondary {
  color: var(--color-secondary) !important;
}

.color-secondary-dark {
  color: var(--color-secondary-dark) !important;
}
.color-tertiary-xlight {
  color: var(--color-tertiary-xlight) !important;
}

.color-tertiary-light {
  color: var(--color-tertiary-light);
}

.color-tertiary {
  color: var(--color-tertiary) !important;
}

.color-tertiary-dark {
  color: var(--color-tertiary-dark) !important;
}

/* *************************************** */
/* *******      END COLORS      ********** */
/* *************************************** */

.bg-primary-light {
  background-color: var(--color-primary-light) !important;
}
.bg-primary-dark {
  background-color: var(--color-primary-dark) !important;
}
.bg-secondary-xlight {
  background-color: var(--color-secondary-xlight) !important;
}
.bg-secondary-light {
  background-color: var(--color-secondary-light) !important;
}
.bg-secondary {
  background-color: var(--color-secondary) !important;
}
.bg-secondary-dark {
  background-color: var(--color-secondary-dark) !important;
}
.bg-secondary-dark {
  background-color: var(--color-secondary-dark) !important;
}
.bg-tertiary-xlight {
  background-color: var(--color-tertiary-xlight) !important;
}

.bg-tertiary-light {
  background-color: var(--color-tertiary-light) !important;
}

.bg-tertiary {
  background-color: var(--color-tertiary) !important;
}

.bg-tertiary-dark {
  background-color: var(--color-tertiary-dark) !important;
}
