/* *************************************** */
/* ********  START VIDEO CARD  *********** */
/* *************************************** */

.videosCardContainer .play-btn {
  top: 25%;
  padding: 5px 13px;
  right: 45%;
  cursor: pointer;
  font-size: 20px;
}
.videosCardContainer .card-img,
.card-img-top {
  border-radius: 15px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
     object-position: top;
  cursor: pointer;
  min-height: 200px;
  max-height: 200px;
}
.react-responsive-modal-modal {
  background: unset;
  box-shadow: unset;
}
.react-responsive-modal-closeButton {
  display: none;
}

/* *************************************** */
/* ********  END VIDEO CARD  *********** */
/* *************************************** */
