.uploadImageContainer {
  height: 420px;
}

.fileContainer {
  border: 1px dotted #3d3d3d;
  border-radius: 32px;
  color: #0000;
}

.upload-icon {
  position: relative;
}
.upload-arrow {
  position: absolute;
  top: 20px;
  right: 10px;
  background: white;
  border-radius: 30px;
  font-size: 20px;
  border: 2px solid var(--color-secondary-dark);
}
/* Demo.css */

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100%;
  width: 100%;
}

.file-drop > .file-drop-target {
  padding-top: 50px;
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -ms-flexbox;

  flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: var(--color-secondary-light);
  box-shadow: none;
  z-index: 50;
  opacity: 1;
  border-radius: 32px;
  /* typography */
  color: white;
}

.uploadImageContainer .alert-success {
  color: var(--color-primary-light);
  background-color: transparent;
}
.uploadImageContainer .alert-danger {
  color: red;
  background-color: transparent;
}
