/* *************************************** */
/* ****  START COMPETITION PAGE TITLE **** */
/* *************************************** */
.comp-title-container p {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-secondary-xlight);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
} /* *************************************** */
/* ****  END COMPETITION PAGE TITLE **** */
/* *************************************** */ ;
