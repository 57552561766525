:root {
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-16: 16px;
  --border-radius-48: 48px;

  --box-shadow: 0px 3px 6px #58585829;
}

* {
  box-sizing: border-box;
  text-align: start;
}

/* *************************************** */
/* *******  START General Style   ******** */
/* *************************************** */

html,
body {
  padding: 0;
  margin: 0;
  color: var(--color-tertiary-xlight);
  background: #121212;
  font-size: 18px;
}
html,
body:lang(ar) {
  direction: rtl;
  font-family: var(--font-light-ar);
}
html,
body:lang(en) {
  direction: ltr;
  font-family: var(--font-light);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
.list-group:lang(ar) {
  padding-right: 0;
}
a {
  color: unset;
}
a:hover {
  text-decoration: none;
  color: unset;
}
.play-btn {
  padding: 5px 15px;
  font-size: 25px;
  border-radius: 30px;
  background-color: #ffffff;
  color: var(--color-primary-dark);
  position: absolute;
}
.play-btn::before {
  font-family: "FontAwesome";
  content: "\f04b";
}
.play-btn {
  top: 45%;
  right: 45%;
  cursor: pointer;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary.focus,
.btn-primary:focus {
  border-color: unset;
  box-shadow: none;
}
.form-control,
.form-control:focus,
.card {
  background: var(--color-secondary-dark);
  border: none;
  outline: none;
  box-shadow: none;
  color: var(--color-tertiary-light);
}
.card-footer {
  background-color: unset;
  border-top: unset;
}
.card-text {
  color: var(--color-tertiary);
}

.dropdown-item {
  text-align: start;
}

.page_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
}
.page_loader_container {
  position: absolute;
  top: 40%;
  text-align: center;
  margin: auto;
  text-align: center;
  width: 100%;
}
.page_loader_container img {
  position: absolute;
  left: 45%;
  top: 35%;
}
.page_loader_container .spinner-border {
  width: 13rem;
  height: 13rem;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  color: var(--color-primary-light);
  background: unset;
}

video {
  outline: unset;
}
/* *************************************** */
/* **** START BORDER RADIUS SIZE ********* */
/* *************************************** */
.border-radius-8 {
  border-radius: var(--border-radius-8) !important;
}

.border-radius-10 {
  border-radius: var(--border-radius-10) !important;
}
.border-radius-16 {
  border-radius: var(--border-radius-16) !important;
}

/* *************************************** */
/* **** END BORDER RADIUS SIZE ********* */
/* *************************************** */

.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.primary-btn,
.secondary-btn {
  background-color: var(--color-primary-dark);
  text-transform: uppercase;
  border-radius: 5px;
}
.secondary-btn {
  border: 1px solid var(--color-primary-light);
  background-color: transparent;
  color: var(--color-primary-dark);
}
.primary-btn {
  border: 1px solid var(--color-primary-dark);
  color: var(--color-tertiary-light);
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:disabled:hover {
  color: #fff;
  background-color: var(--color-secondary-xlight);
  border-color: unset;
  border: unset;
  cursor: no-drop;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: var(--color-primary-light);
  border-color: unset;
  border: 1px solid var(--color-primary-dark);
}
.dropdown-menu {
  background-color: var(--color-secondary);
}
.dropdown-menu .dropdown-item {
  color: var(--color-tertiary);
}
.dropdown-menu .dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  color: var(--color-primary-light);
  background: unset;
}

.player-wrapper {
  width: 100%;
}

video {
  -o-object-fit: cover;
  object-fit: cover;
}
/* *************************************** */
/* *******  END General Style   ********** */
/* *************************************** */

/* *************************************** */
/* ****  START Subscription Page   ****** */
/* *************************************** */
.subscription-image {
  background-image: url(/src/assets/img/intro.jpg);
  background-position: top;
  background-size: cover;
}
h1 {
  overflow: hidden;
  text-align: center;
}

.otherLogin:before,
.otherLogin:after {
  background-color: var(--color-secondary-xlight);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 40%;
}

.otherLogin:before {
  right: 0.5em;
  margin-left: -50%;
}

.otherLogin:after {
  left: 0.5em;
  margin-right: -50%;
}

/* *************************************** */
/* ****  END Subscription Page   ****** */
/* *************************************** */

/* .nutrition-btn-grp .btn {
  background-color: unset;
  color: var(--color-ffffff);
  border: unset;
}
.nutrition-btn-grp .active {
  background-color: var(--color-primary-dark);
  color: var(--color-ffffff);
} */

.react-responsive-modal-modal {
  width: 50%;
}

@media (max-width: 1199.98px) {
  .react-responsive-modal-modal {
    width: 100%;
  }
}

/* ////////////////////////////////////////////// */
